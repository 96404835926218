// src\hooks\useAccountVars.js
import { useState, useEffect } from "react";

export function useAccountVars(user) {
  const [acctVars, setAcctVars] = useState(null);

  // Define the function that fetches account variables
  const fetchAccountVars = async () => {
    console.log("Checking localStorage for 'rb_acct_vars'...");
    const cachedData = localStorage.getItem("rb_acct_vars");

    if (cachedData) {
      console.log("Cached data found in localStorage:", cachedData);
      setAcctVars(JSON.parse(cachedData));
    } else if (user?.email) {
      console.log("No cached data found. Fetching data for email:", user.email);
      try {
        const response = await fetch("/api/getAccountVars", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: user.email }),
        });

        const data = await response.json();
        console.log("API response data:", data);

        if (data && Object.keys(data).length > 0) {
          console.log("Data fetched successfully. Storing in localStorage...");
          localStorage.setItem("rb_acct_vars", JSON.stringify(data));
          setAcctVars(data);
        } else {
          console.warn(
            "No data returned from the API. Avoiding loop by setting 'rb_acct_vars_error'..."
          );
          localStorage.setItem("rb_acct_vars_error", "true");
        }
      } catch (err) {
        console.error("Error fetching account vars:", err);
        localStorage.setItem("rb_acct_vars_error", "true");
      }
    } else {
      console.warn("User email not available. Skipping fetch...");
    }
  };

  // Fetch account variables on mount and whenever user changes
  useEffect(() => {
    fetchAccountVars();
  }, [user]);

  // Return acctVars and a refetch function so the component can manually refresh
  return { acctVars, refetch: fetchAccountVars };
}
