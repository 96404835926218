/* eslint-disable prettier/prettier */
// src/app.js

import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import Intercom from "@intercom/messenger-js-sdk";

import { CallbackPage } from "./pages/callback-page";
import GMBIntegrationPage from "./pages/gmb-integration";
import ReviewsPage from "./pages/reviews";
import BrandRulesPage from "./pages/brand-rules";
import Post from "./pages/post";

import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { OnboardingPage } from "./pages/onboarding-page";
import { PublicPage } from "./pages/public-page";
import SettingsPage from "./pages/settings-page";
import DashboardPage from "./pages/dashboard-page";

// Import the custom hook
import { useAccountVars } from "./hooks/useAccountVars";

export const App = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  // Use the custom hook to get account-specific variables
  const acctVars = useAccountVars(user);

  useEffect(() => {
    if (isAuthenticated && user) {
      // Convert updated_at to UNIX timestamp in seconds
      const createdAt = user.updated_at
        ? Math.floor(new Date(user.updated_at).getTime() / 1000)
        : undefined;

      // Initialize Intercom
      Intercom({
        app_id: "yt75wy1u",
        user_id: user.email,
        name: user.name || "Guest",
        email: user.email,
        created_at: createdAt,
      });
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <div className="app-layout">
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HomePage acctVars={acctVars} />} />
          <Route
            path="/gmb-integration"
            element={
              <AuthenticationGuard
                component={GMBIntegrationPage}
                acctVars={acctVars}
              />
            }
          />
          <Route
            path="/reviews"
            element={
              <AuthenticationGuard
                component={ReviewsPage}
                acctVars={acctVars}
              />
            }
          />
          <Route
            path="/brand-rules"
            element={
              <AuthenticationGuard
                component={BrandRulesPage}
                acctVars={acctVars}
              />
            }
          />
          <Route path="/post" element={<Post acctVars={acctVars} />} />
          <Route
            path="/onboarding"
            element={
              <AuthenticationGuard
                component={OnboardingPage}
                acctVars={acctVars}
              />
            }
          />
          <Route path="/public" element={<PublicPage acctVars={acctVars} />} />
          <Route
            path="/dashboard"
            element={
              <AuthenticationGuard
                component={DashboardPage}
                acctVars={acctVars}
              />
            }
          />
          <Route
            path="/settings"
            element={
              <AuthenticationGuard
                component={SettingsPage}
                acctVars={acctVars}
              />
            }
          />
          <Route path="/callback" element={<CallbackPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </div>
  );
};
