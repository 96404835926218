// src/hooks/useUpdateAccountVars.js
import { useState } from "react";

/**
 * A custom hook to update account variables in the database.
 * @returns {Object} - { updateAccountVars, isLoading, error, data }
 */
export function useUpdateAccountVars() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  /**
   * Calls the /api/updateAccountVars endpoint to update user data.
   * @param {string} email - The user's email, used as the identifier.
   * @param {Object} updates - Key-value pairs to update in the DB.
   */
  async function updateAccountVars(email, updates) {
    setIsLoading(true);
    setError(null);
    setData(null);

    try {
      const response = await fetch("/api/updateAccountVars", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, updates }),
      });

      if (!response.ok) {
        const err = await response.json();
        throw new Error(err.error || "Failed to update account vars");
      }

      const responseData = await response.json();
      setData(responseData);

      // Update localStorage with the new data
      localStorage.setItem("rb_acct_vars", JSON.stringify(responseData));
    } catch (err) {
      console.error("Error in updateAccountVars hook:", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return { updateAccountVars, isLoading, error, data };
}
