/* eslint-disable prettier/prettier */
// src/pages/gmb-integration.js

import React from "react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useFetchLocations from "../hooks/useFetchLocations";
import { useAuth0 } from "@auth0/auth0-react";
import Retool from "react-retool";
import { useAccountVars } from "../hooks/useAccountVars";
import { useUpdateViaIframe } from "../hooks/useUpdateViaIframe";

const GMBIntegrationPage = () => {
  useDocumentTitle("GMB Integration");

  const { user } = useAuth0();
  const { locations, authData, loading, error } = useFetchLocations();
  const { refetch } = useAccountVars(user);

  // Use custom hook for iframe updates, passing user email directly
  useUpdateViaIframe(
    "https://tryrepbot.retool.com",
    "gmb_connected",
    true,
    refetch,
    user?.email // Pass user email directly
  );

  const retoolData = {
    userName: user.name,
    userEmail: user.email,
    locations: locations ? locations : [],
    authData: authData ? authData : {},
  };

  if (loading) {
    return <p>Loading business location names...</p>;
  }

  if (error) {
    return <p>Error loading locations: {error.message}</p>;
  }

  return (
    <PageLayout>
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          GMB Integration
        </h1>
        <Retool
          url="https://tryrepbot.retool.com/p/gmb-integration"
          width="100%"
          height="800px"
          data={retoolData}
        />
      </div>
    </PageLayout>
  );
};

export default GMBIntegrationPage;
