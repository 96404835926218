/* eslint-disable prettier/prettier */
// src/hooks/useUpdateViaIframe.js

import { useEffect } from "react";
import { useUpdateAccountVars } from "./useUpdateAccountVars";

/**
 * Custom hook to handle iframe messages and update the database.
 * @param {string} expectedOrigin - The origin to validate incoming messages.
 * @param {string} keyName - The key this hook is responsible for updating.
 * @param {any} value - The value to set for the key.
 * @param {Function} refetch - Function to refetch local data.
 * @param {string} userEmail - The user's email, required for updates.
 */
export const useUpdateViaIframe = (
  expectedOrigin,
  keyName,
  value,
  refetch,
  userEmail
) => {
  const { updateAccountVars } = useUpdateAccountVars();

  useEffect(() => {
    const handleMessage = async (event) => {
      if (event.origin !== expectedOrigin) {
        console.warn(`Ignored message from unexpected origin: ${event.origin}`);
        return;
      }

      if (event.data && event.data.triggerUpdate) {
        // Check if the message is for the correct field
        if (event.data.field === keyName) {
          try {
            if (!userEmail) {
              console.error(
                "User email is missing, cannot proceed with update."
              );
              return;
            }

            // Update the database
            await updateAccountVars(userEmail, { [keyName]: event.data.value });

            // Refresh local data if provided
            if (refetch) {
              refetch();
            }
          } catch (error) {
            console.error("Error updating account vars via iframe:", error);
          }
        } else {
          console.warn(`Ignored update for field: ${event.data.field}`);
        }
      }
    };

    // Add the event listener for messages
    window.addEventListener("message", handleMessage);

    return () => {
      // Remove the event listener on cleanup
      window.removeEventListener("message", handleMessage);
    };
  }, [expectedOrigin, keyName, value, updateAccountVars, refetch, userEmail]);
};
