/* eslint-disable prettier/prettier */
// src/pages/reviews.js

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal"; // Import the Modal component
import GoogleSSO from "../components/GoogleSSO"; // Import Google SSO Component
import useIframeModal from "../hooks/useIframeModal"; // Import the modal hook
import useIframeRedirect from "../hooks/useIframeRedirect"; // Import the redirect hook

const ReviewsPage = () => {
  useDocumentTitle("Reviews");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [paid, setPaid] = useState(false); // State to track if the user is paid
  const [toneSaved, setToneSaved] = useState(false); // State to track if tone is saved

  // Initialize the iframe modal hook with the expected origin and callback
  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  useEffect(() => {
    const rbAccountVars = localStorage.getItem("rb_acct_vars");
    if (rbAccountVars) {
      try {
        const accountVars = JSON.parse(rbAccountVars);
        setPaid(!!accountVars.paid); // Update paid based on localStorage value
        setToneSaved(!!accountVars.tone_saved); // Update toneSaved based on localStorage value
      } catch (error) {
        console.error("Error parsing rb_acct_vars:", error);
      }
    }
  }, []);

  // Data to pass to Retool, customize as needed
  const retoolData = {
    userEmail: user.email,
    // Add other necessary data here
  };

  // Determine the iframe URL based on conditions
  const iframeUrl = !paid
    ? "https://tryrepbot.retool.com/p/reviews-free"
    : toneSaved
    ? "https://tryrepbot.retool.com/p/reviews-gmb-tone-yes"
    : null;

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}

      {iframeUrl ? (
        <Retool
          url={iframeUrl} // Dynamically set the URL based on conditions
          width="100%"
          height="1000px"
          data={retoolData} // Pass all necessary data to Retool
        />
      ) : (
        <div
          style={{ textAlign: "center", marginTop: "50px", minHeight: "70vh" }}
        >
          <h1>Finalize Your Brand Rules </h1>
          <h2>
            Before we can generate tailored responses for you, please review and
            save your <a href="/brand-rules">Brand Rules</a>. <br /> Once saved,
            your personalized review responses will be ready to display here.
          </h2>
        </div>
      )}
    </PageLayout>
  );
};

export default ReviewsPage;
