/* eslint-disable prettier/prettier */
// src/pages/brand-rules.js

import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import useIframeRedirect from "../hooks/useIframeRedirect"; // Keep the redirect hook
import { useAccountVars } from "../hooks/useAccountVars";
import { useUpdateViaIframe } from "../hooks/useUpdateViaIframe";

const BrandRulesPage = () => {
  useDocumentTitle("Brand Rules");

  const { user } = useAuth0();
  const { refetch } = useAccountVars(user); // Fetch account variables and refetch function
  const [isPaid, setIsPaid] = useState(false);

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  // Use custom hook to listen for iframe messages and update tone_saved
  useUpdateViaIframe(
    "https://tryrepbot.retool.com",
    "tone_saved",
    true,
    refetch,
    user?.email // Pass user email directly
  );

  useEffect(() => {
    const rbAccountVars = localStorage.getItem("rb_acct_vars");
    if (rbAccountVars) {
      try {
        const accountVars = JSON.parse(rbAccountVars);
        setIsPaid(!!accountVars.paid); // Update isPaid based on localStorage value
      } catch (error) {
        console.error("Error parsing rb_acct_vars:", error);
      }
    }
  }, []);

  // Data to pass to Retool, customize as needed
  const retoolData = {
    userEmail: user.email,
    // Add other necessary data here
  };

  return (
    <PageLayout>
      {isPaid ? (
        <Retool
          url="https://tryrepbot.retool.com/p/brand-rules" // Updated URL for your Retool app
          width="100%"
          height="1000px"
          data={retoolData} // Pass all necessary data to Retool
        />
      ) : (
        <div
          style={{ textAlign: "center", marginTop: "50px", minHeight: "70vh" }}
        >
          <h1>Paid Feature</h1>
          <h2>Please upgrade your account to enable this feature</h2>
        </div>
      )}
    </PageLayout>
  );
};

export default BrandRulesPage;
