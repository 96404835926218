/* eslint-disable prettier/prettier */
// src/pages/dashboard-page.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PageLayout } from "../components/page-layout";
import useDocumentTitle from "../hooks/useDocumentTitle";
import Retool from "react-retool";
import Modal from "../components/modal";
import GoogleSSO from "../components/GoogleSSO";
import useIframeModal from "../hooks/useIframeModal"; // Only removing useAccountInfo
import useIframeRedirect from "../hooks/useIframeRedirect"; // Import the redirect hook

const DashboardPage = () => {
  useDocumentTitle("Dashboard");

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gmbConnected, setGmbConnected] = useState(false);

  // Initialize the iframe modal hook with the expected origin and callback
  useIframeModal("https://tryrepbot.retool.com", () => setIsModalOpen(true));

  // Initialize the iframe redirect hook with the expected origin
  useIframeRedirect("https://tryrepbot.retool.com");

  // Check localStorage for gmb_connected under rb_account_vars
  useEffect(() => {
    const rbAccountVars = localStorage.getItem("rb_acct_vars");
    if (rbAccountVars) {
      try {
        const accountVars = JSON.parse(rbAccountVars);
        setGmbConnected(!!accountVars.gmb_connected);
      } catch (error) {
        console.error("Error parsing rb_account_vars:", error);
      }
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };

  const iframeUrl = gmbConnected
    ? "https://tryrepbot.retool.com/p/dashboard-gmb"
    : "https://tryrepbot.retool.com/p/dashboard-manual";

  const retoolData = {
    accountInfo: {
      userEmail: user.email,
    },
  };

  return (
    <PageLayout>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <GoogleSSO />
        </Modal>
      )}

      <Retool url={iframeUrl} width="100%" height="800px" data={retoolData} />
    </PageLayout>
  );
};

export default DashboardPage;
